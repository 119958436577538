<template>
  <div class="home matches-page">
    <PageTitle>Pro Matches</PageTitle>

    <AppTabs lazy>
      <AppTab title="Search">
        <SearchStateController ref="searchCtrl" :default="defaultFilters" :s="s" @change="onSearchChange">
          <template #default="{ state }">
            <SearchFilters :disabled="!!loading" :value="state" @update="onSearch" />

            <hr />

            <div class="alert alert-info d-flex justify-content-between" v-if="exporting">
              <div class="d-flex align-items-center">
                <b-spinner small type="grow" variant="primary" />
                <span class="ml-2">Exporting...</span>
              </div>
              <button class="btn btn-outline-danger btn-sm" @click="cancelExport">Cancel</button>
            </div>

            <div class="alert alert-danger d-flex justify-content-between" v-if="error">
              <div class="d-flex align-items-center">
                <b-icon-exclamation-triangle />
                <span class="ml-2" style="white-space: pre-wrap">{{ error }}</span>
              </div>
              <button class="btn btn-outline-danger btn-sm" @click="error = null">Dismiss</button>
            </div>

            <AppTabs>
              <AppTab title="Found matches">
                <MatchSearchController
                  :filter="state"
                  :per-page="perPage"
                  @update:per-page="updateItemsPerPage"
                  :selected.sync="selectedMatches"
                  :table="$options.components.MatchBookmarkController"
                  :tracking-context="{ page: 'matches', section: 'results' }"
                  @export="exportMatch"
                />
              </AppTab>

              <AppTab :title="`Selected matches (${selectedMatchesCount})`">
                <div class="list-actions">
                  <BButton :disabled="!selectedMatchesCount" @click="exportSelectedMatches">
                    Export {{ selectedMatchesCount }} {{ selectedMatchesCount !== 1 ? 'matches' : 'match' }}
                  </BButton>
                </div>
                <MatchBookmarkController
                  :items="selectedMatches"
                  :selected.sync="selectedMatches"
                  :tracking-context="{ page: 'matches', section: 'selected' }"
                />
              </AppTab>

              <MapTab
                :active.sync="mapTool.active"
                :matches="selectedMatches"
                :map.sync="mapTool.map"
                :team.sync="mapTool.team"
                :tracking-context="{ page: 'matches', section: 'map' }"
              />

              <!-- <AppTab lazy :disabled="!selectedMatches.length" title="NEW Map">
                <MapTool v-bind="mapTool" :matches="selectedMatches" />
              </AppTab> -->
            </AppTabs>
          </template>
        </SearchStateController>
      </AppTab>

      <AppTab title="Bookmarked">
        <BookmarkedMatchesController
          :per-page="perPage"
          @update:per-page="updateItemsPerPage"
          :tracking-context="{ page: 'matches', section: 'bookmarked' }"
        />
      </AppTab>
    </AppTabs>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { BIconExclamationTriangle } from 'bootstrap-vue'
import px from 'vue-types'
import { mapGetters, mapMutations } from 'vuex'

import { DEFAULT_ITEMS_PER_PAGE } from '@/store/modules/search'
import downloadMatchesExport from '@/utils/downloadMatchesExport'

import MatchBookmarkController from '../components/controllers/bookmark/Match.vue'
import BookmarkedMatchesController from '../components/controllers/BookmarkedMatches.vue'
import MatchSearchController from '../components/controllers/search/Match.vue'
import SearchStateController from '../components/controllers/SearchStateController.vue'
import PageTitle from '../components/generic/PageTitle.vue'
import AppTab from '../components/generic/Tab.vue'
import AppTabs from '../components/generic/Tabs.vue'
import { pxNullable } from '../components/Map/types.js'
import SearchFilters from '../components/Search/Filters.vue'
import MapTab from '../components/tabs/MapTab.vue'
import mixpanel from '../mixpanel.js'
import { serializeQuery } from '../utils/searchQuery.js'

export default {
  name: 'Matches',
  components: {
    BIconExclamationTriangle,
    SearchStateController,
    MatchSearchController,
    BookmarkedMatchesController,
    MapTab,
    MatchBookmarkController,
    AppTab,
    AppTabs,
    SearchFilters,
    PageTitle,
  },
  props: {
    s: pxNullable(px.string).def(null),
  },
  data() {
    return {
      exporting: false,
      error: null,
      loading: 0,
      perPage: DEFAULT_ITEMS_PER_PAGE,
      mapTool: {
        active: false,
        map: null,
        team: null,
      },
      selectedMatches: [],
    }
  },
  computed: {
    ...mapGetters({
      defaultFilters: 'search/matchSearchFilters',
      defaultItemsPerPage: 'search/itemsPerPage',
    }),
    selectedMatchesCount() {
      return this.selectedMatches?.length
    },
  },
  mounted() {
    this.perPage = this.defaultItemsPerPage
  },
  methods: {
    ...mapMutations({
      resetFilters: 'search/RESET_FILTERS',
      saveFilters: 'search/SET_FILTERS',
      setItemsPerPage: 'search/SET_ITEMS_PER_PAGE',
    }),

    cancelExport() {
      if (this.exporting) {
        this.exporting.abort()
        this.exporting = false
      }
    },

    async doExport(match_ids, source) {
      this.error = null
      this.exporting = new AbortController()
      try {
        await downloadMatchesExport(match_ids, {
          trackOpts: { source },
          signal: this.exporting.signal,
        })
      } catch (e) {
        if (e.name !== 'AbortError') {
          this.error = e
          Sentry.captureException(e)
        }
      } finally {
        this.exporting = false
      }
    },

    exportMatch(match) {
      this.doExport([match.id], 'matches list')
    },

    exportSelectedMatches() {
      this.doExport(
        this.selectedMatches.map(({ id }) => id),
        'selected matches'
      )
    },

    async onSearch(query) {
      this.loading++
      try {
        this.selectedMatches = []
        const serializedQuery = await serializeQuery(query)
        if (query == null) {
          this.resetFilters()
        } else {
          this.saveFilters(query)
        }
        if (this.s !== serializedQuery) {
          await this.$router.push({ query: { s: serializedQuery != null ? serializedQuery : undefined } })
        } else {
          await this.$refs.searchCtrl.retry()
        }
      } finally {
        this.loading--
      }

      mixpanel.track_search(query, query ? 'Search' : 'Reset')
    },

    onSearchChange() {
      this.selectedMatches = []
    },

    updateItemsPerPage(count) {
      this.perPage = count
      this.setItemsPerPage(count)
    },
  },
}
</script>

<style lang="scss" scoped>
.list-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}
</style>
