<template>
  <ApiListController :fetch="fetch" name="Matches">
    <template #default="{ items }">
      <MatchBookmarkController :items="items" />
    </template>
  </ApiListController>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { listBookmarks } from '@/api/bookmarks.js'

import ApiListController from './ApiListController.vue'
import MatchBookmarkController from './bookmark/Match.vue'

export default {
  name: 'BookmarkedMatchesController',
  components: {
    ApiListController,
    MatchBookmarkController,
  },
  computed: {
    ...mapGetters({
      globalBookmarksCount: 'bookmarks/countMatches',
    }),
  },
  watch: {
    globalBookmarksCount(value) {
      this.$el.dispatchEvent(new CustomEvent('status', { detail: `(${value})`, bubbles: true }))
    },
  },
  methods: {
    ...mapActions({
      listBookmarks: 'bookmarks/list',
    }),
    async fetch(params, config) {
      const result = await listBookmarks({
        ...config,
        ...params,
        type: 'match',
      })
      return {
        data: result.bookmarks,
        total: result.count,
      }
    },
  },
}
</script>
