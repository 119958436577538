import { exportMatches } from '@/api/export'
import mixpanel from '@/mixpanel'
import download from '@/utils/download'

/**
 * @param {string[]} match_ids
 * @param {AbortSignal} signal
 * @param {object} trackOpts
 * @returns {Promise<void>}
 */
export default async function downloadMatchesExport(match_ids, { signal, trackOpts } = {}) {
  console.log('exporting matches', match_ids)
  const content = await exportMatches(match_ids, { signal })
  if (signal.aborted) {
    throw signal.reason || new DOMException('Aborted', 'AbortError')
  }
  download(content, 'matches.xlsx')
  mixpanel.track_export('match', match_ids, trackOpts)
}
