import { getExtraBackends } from '@/axios'
import storage from '@/store/storage'

const ACCEPT = {
  json: 'application/json',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

/**
 * @param {string[]} match_ids
 * @param {AbortSignal} signal
 * @param {'json'|'xlsx'} format
 * @returns {Promise<string>}
 */
export async function exportMatches(match_ids, { format = 'xlsx', signal } = {}) {
  if (!ACCEPT[format]) {
    throw new Error(`Unknown export format: ${format}`)
  }

  const res = await fetch(`${getExtraBackends().export}/esport/matches`, {
    method: 'post',
    mode: 'cors',
    body: JSON.stringify({ match_ids }),
    headers: {
      'Accept': ACCEPT[format],
      'Content-Type': 'application/json',
      'X-Augment-Session-Key': storage.getItem('SESSION_KEY'),
    },
    signal,
  })
  if (res) {
    if (!res.ok) {
      throw new Error(`Error exporting matches: ${res.status} ${res.statusText}\n${await res.text()}`)
    }

    let unregister
    const abortPromise = new Promise((resolve, reject) => {
      if (!signal) {
        return
      }
      const listener = () => {
        reject(signal.reason || new DOMException('Aborted', 'AbortError'))
      }
      signal.addEventListener('abort', listener)
      unregister = () => signal.removeEventListener('abort', listener)
    })

    try {
      switch (format) {
        case 'json':
          return await Promise.race([abortPromise, res.json()])
        case 'xlsx':
          return URL.createObjectURL(await Promise.race([abortPromise, res.blob()]))
        default:
          throw new Error(`Unknown export format ${format}`)
      }
    } finally {
      unregister?.()
    }
  }
}

/**
 * @param {string[]} match_ids
 * @param {AbortSignal} signal
 * @param {'json'|'xlsx'} format
 * @returns {Promise<string>}
 */
export async function exportGridMatch({ seriesId, map, apiKey, redTeam, blueTeam }, { format = 'xlsx', signal } = {}) {
  if (!ACCEPT[format]) {
    throw new Error(`Unknown export format: ${format}`)
  }

  const res = await fetch(
    `https://ncpasz5bgux6ag64szfhpxodse0usuii.lambda-url.us-east-1.on.aws/grid/series/${encodeURIComponent(
      seriesId
    )}/${encodeURIComponent(map)}/stats`,
    {
      method: 'post',
      headers: {
        'Accept': ACCEPT[format],
        'Content-Type': 'application/json',
        'X-Augment-Session-Key': storage.getItem('SESSION_KEY'),
        ...(apiKey && { 'X-Grid-Api-Key': apiKey }),
      },
      body: JSON.stringify({
        atk_team: redTeam,
        def_team: blueTeam,
      }),
      signal,
    }
  )
  if (res) {
    if (!res.ok) {
      throw new Error(`Error exporting matches: ${res.status} ${res.statusText}\n${await res.text()}`)
    }

    let unregister
    const abortPromise = new Promise((resolve, reject) => {
      if (!signal) {
        return
      }
      const listener = () => {
        reject(signal.reason || new DOMException('Aborted', 'AbortError'))
      }
      signal.addEventListener('abort', listener)
      unregister = () => signal.removeEventListener('abort', listener)
    })

    try {
      switch (format) {
        case 'json':
          return await Promise.race([abortPromise, res.json()])
        case 'xlsx':
          return URL.createObjectURL(await Promise.race([abortPromise, res.blob()]))
        default:
          throw new Error(`Unknown export format ${format}`)
      }
    } finally {
      unregister?.()
    }
  }
}
