<template>
  <FilterSelection
    :disabled="disabled"
    :get-item-id="getPlayerId"
    :get-item-string-compare="getPlayerName"
    v-model="internalValue"
  >
    <template #selection="{ item }">
      <PlayerCell v-bind="item" variant="row" />
    </template>
    <AppAutosuggest
      :disabled="disabled"
      :input-props="inputProps"
      :section-configs="sectionConfigs"
      :suggestions="sectionedSuggestions"
      :value="focused ? search : placeholder"
      @input="onInput"
      @selected="onSelect"
      @focus="onFocus"
      @blur="onBlur"
    >
      <template #before-suggestions>
        <Loading v-if="loading" />
        <ErrorAlert v-else-if="error">
          {{ error }}
        </ErrorAlert>
        <InfoAlert v-else-if="!suggestions.length && search.length < 3">
          Enter at least 3 characters to get results
        </InfoAlert>
        <InfoAlert v-else-if="!suggestions.length"> No matching players </InfoAlert>
      </template>
      <template #default="{ suggestion }">
        <DropdownItem :disabled="disabled" :selected="selectedPlayerIds[suggestion.item.id]">
          <PlayerCell v-bind="suggestion.item" variant="row" />
        </DropdownItem>
      </template>
    </AppAutosuggest>
    <FilterOption :value="option" @change="onOptionChange" />
  </FilterSelection>
</template>

<script>
import * as Sentry from '@sentry/vue'
import px from 'vue-types'

import axios from '@/axios.js'
import AppAutosuggest from '@/components/generic/Autosuggest.vue'
import FilterSelection from '@/components/Search/filters/FilterSelection.vue'
import PlayerCell from '@/components/Table/cells/PlayerCell.vue'

import { getEsportPlayersAutocomplete } from '../../../api/autocomplete.js'
import { pxOption } from '../../../types.js'
import DropdownItem from '../../Form/DropdownItem.vue'
import ErrorAlert from '../../generic/ErrorAlert.vue'
import InfoAlert from '../../generic/InfoAlert.vue'
import Loading from '../../generic/Loading.vue'

import FilterOption from './FilterOption.vue'

export default {
  name: 'PlayerFilter',
  components: {
    AppAutosuggest,
    DropdownItem,
    ErrorAlert,
    FilterOption,
    FilterSelection,
    InfoAlert,
    Loading,
    PlayerCell,
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    disabled: px.bool.def(false),
    option: pxOption(),
    value: px.arrayOf(px.object),
  },
  data() {
    return {
      error: null,
      focused: false,
      loading: 0,
      search: '',
      suggestions: [],
    }
  },
  computed: {
    inputProps() {
      return {
        class: 'form-control',
        id: 'player-search-filter',
        placeholder: this.placeholder,
      }
    },

    internalValue: {
      get: function () {
        return this.value || []
      },
      set: function (value) {
        return this.$emit('update', this.option === 'and' ? value.slice(0, 5) : value)
      },
    },

    placeholder() {
      switch (this.internalValue.length) {
        case 0:
          return 'Any player'
        case 1:
          return '1 player selected'
        default:
          return `${this.internalValue.length} players selected`
      }
    },

    sectionConfigs() {
      return {
        default: {},
        empty: { type: 'div' },
        error: { type: 'div' },
        loading: { type: 'div' },
      }
    },

    sectionedSuggestions() {
      if (this.loading) return [{ name: 'loading', data: [{ loading: true }] }]
      if (this.error) return [{ name: 'error', data: [{ error: this.error }] }]
      if (!this.suggestions.length) return [{ name: 'empty', data: [{ empty: true }] }]
      return [{ data: this.suggestions }]
    },

    selectedPlayerIds() {
      return Object.freeze(Object.fromEntries(this.internalValue.map(player => [this.getPlayerId(player), true])))
    },
  },
  beforeDestroy() {
    this.cancelRequest()
  },
  methods: {
    cancelRequest() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
    },
    getPlayerId(player) {
      return player.id
    },
    getPlayerName(player) {
      return player.name
    },
    async onInput(search) {
      this.search = search
      this.cancelRequest()
      this.suggestions = []
      this.error = null
      if (search.length < 3) return
      this.cancelTokenSource = axios.CancelToken.source()
      this.loading++
      try {
        const data = await getEsportPlayersAutocomplete(search, {
          cancelToken: this.cancelTokenSource.token,
        })
        this.suggestions = Object.freeze((data || []).map(item => Object.freeze(item)))
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }
        this.error = axios.extractErrorMessage(e)
        console.error(this.error, e)
        Sentry.captureException(e)
      } finally {
        this.loading--
      }
    },
    onOptionChange($event) {
      this.$emit('update:option', $event)
      if ($event === 'and') {
        this.internalValue = this.internalValue.slice(0, 5)
      }
    },
    onSelect(selection) {
      if (!selection) return
      const { item: player } = selection
      const playerId = this.getPlayerId(player)
      this.internalValue = this.selectedPlayerIds[playerId]
        ? // remove
          this.internalValue.filter(player => this.getPlayerId(player) !== playerId)
        : // add
          [...this.internalValue, player]
      this.search = ''
      this.suggestions = []
    },
    onFocus() {
      this.focused = true
      this.suggestions = []
    },
    onBlur() {
      this.focused = false
      this.search = ''
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .alert {
    margin: 0;
    border-radius: 0;
  }
}
</style>
